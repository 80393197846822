<template>
  <v-row v-if="current"
         justify="center">
    <v-col class="mt-5"
           cols="10"
           md="5">
      <markdown-component class="mt-4"
                          :source="current.text" />
    </v-col>
  </v-row>
</template>

<script>
import GuideLogic from './guide-logic.vue';
import MarkdownComponent from '../../../components/markdown-component.vue';

export default {
  name: 'guide-without-button-component',
  extends: GuideLogic,
  components: { MarkdownComponent },
};
</script>
