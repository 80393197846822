<script>
import Guides from '../../../model/guides.enum';

export default {
  name: 'guide-logic',
  props: {
    guideData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    Guides,
    idxGuide: 0,
    current: null,
    guide: null,
    guideId: null,
  }),
  created() {
    this.$store.dispatch('GET_GUIDE')
      .then((doc) => {
        this.guideId = doc.guideId;
        this.guide = doc.guide;
        this.assingCurrent();
      });
  },
  computed: {
    isFirstGuide() {
      return this.idxGuide === 0;
    },
    isLastGuide() {
      return (this.guide.length - 1) === this.idxGuide;
    },
  },
  methods: {
    next() {
      this.idxGuide += 1;
      this.assingCurrent();
    },
    back() {
      if (Guides.BEFORE_REGISTER === this.guideId) {
        this.backForBeforeSignup();
      } else {
        this.goToBackGuide();
      }
    },
    backForBeforeSignup() {
      const FIRST_INDEX = 0;
      const IsFirstGuide = this.idxGuide === FIRST_INDEX;
      if (IsFirstGuide) {
        this.$router.replace('/access');
      } else {
        this.goToBackGuide();
      }
    },
    goToBackGuide() {
      this.idxGuide -= 1;
      this.assingCurrent();
    },
    assingCurrent() {
      this.current = this.guide[this.idxGuide];
    },
    ended() {
      this.$emit('ended');
    },
  },
};
</script>
